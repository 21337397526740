if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

import '../scss/app.scss';
import '../scss/app-tourism.scss';
import '../scss/app-editor.scss';
import './bootstrap.bundle';
import { UiCpnt } from './components/ui_cpnt';
import { UxCpnt } from './components/ux_cpnt';
import { Wpcf7Cpnt } from './components/wpcf7_cpnt';
import { MapCpnt } from './components/map_cpnt';
import { TourismCpnt } from './components/tourism_cpnt';
import { BrochureCpnt } from './components/brochure_cpnt';
import { GalleryCpnt } from './components/gallery_cpnt';
import { ShuttleCpnt } from './components/shuttle_cpnt';
import { RelatedPostCpnt } from './components/related-post_cpnt';

document.addEventListener( "DOMContentLoaded", function () {
    const ObjUiCpnt             = new UiCpnt();
    /*
    Fouteur de merde
     */
    const ObjUxCpnt             = new UxCpnt();
    /*
    /----
     */
    const ObjWpcf7Cpnt          = new Wpcf7Cpnt();
    const ObjMapCpnt            = new MapCpnt();
    const ObjTourismCpnt        = new TourismCpnt();
    const ObjBrochureCpnt       = new BrochureCpnt();
    const ObjGalleryCpnt        = new GalleryCpnt();
    const ObjShuttleCpnt        = new ShuttleCpnt();
    const ObjRelatedPostCpnt    = new RelatedPostCpnt();
} );