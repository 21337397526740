import 'slick-carousel/slick/slick.min';
import 'jquery-match-height/dist/jquery.matchHeight-min';

export class RelatedPostCpnt {
	constructor() {
		this.initializeSlick();
		this.initializeSlickSIT();
	}

	initializeSlick() {
		const slider = $('[data-slider="wpiris-related-post"] > [data-slider="wpiris-related-post-wrapper"]');

		slider.slick({
			slidesPerRow: 4,
			slidesToShow: 4,
			slidesToScroll: 4,
			infinite: true,
			speed: 500,
			prevArrow: $('.wp-block-related-post-slider-controls .slick-prev'),
			nextArrow: $('.wp-block-related-post-slider-controls .slick-next'),
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		})
	}

	initializeSlickSIT() {
		const slider = $('[data-slider="wpiris-related-post-sit"] > [data-slider="wpiris-related-post-wrapper"]');

		slider.slick({
			slidesPerRow: 4,
			slidesToShow: 4,
			slidesToScroll: 4,
			infinite: true,
			speed: 500,
			prevArrow: $('[data-slider="controls-sit"] .slick-prev'),
			nextArrow: $('[data-slider="controls-sit"] .slick-next'),
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		})
	}

}